.inventory-container {
    font-family: 'Stolzl', sans-serif;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    padding: 0 5.5rem;
}
.inventory-title-container{
    width: 100%;
    background-color: #ecdf00;
    display: flex;
    font-family: Arial, sans-serif;
    justify-content: start;
    padding: 20px 0px;
}
.inventory-title-header{
    color: black;
    font-size: 30px;          
    font-weight: bold;
    margin-left: 1rem;
}
.inventory-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px; 
}

.inventory-title h1 {
    font-size: 5rem;
    margin-bottom: 0;
}

.inventory-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.inventory-info span {
    font-size: 1.5rem;
}

.sort-dropdown-container {
    text-align: right;
}

.sort-dropdown {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.filter-section .row {
    display: flex;
    box-shadow: 0 4px 2px -2px gray;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px; 
}

.filter-section .col-lg {
    flex: 1;
    margin: 5px;
    display: flex;
    justify-content: center; 
}

.search-bar,
.year-dropdown,
.brand-dropdown,
.model-dropdown,
.search-button {
    width: 100%;
    max-width: 250px;
    padding: 12px 20px;
    border: 1px solid #000000;
    border-radius: 25px; 
    text-align: center;
    background-color: #ffffff;
    color: black;
    font-family: 'Stolzl', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.year-dropdown,
.brand-dropdown,
.model-dropdown {
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none; 
    background-color: white; 
    color: black; 
}

.year-dropdown:hover,
.brand-dropdown:hover,
.model-dropdown:hover,
.year-dropdown:focus,
.brand-dropdown:focus,
.model-dropdown:focus {
    background-color: #000; 
    color: white; 
}

.year-dropdown::after,
.brand-dropdown::after,
.model-dropdown::after {
    content: '\25BC'; 
    position: absolute;
    right: 10px;
    pointer-events: none;
}

.search-button {
    background-color: #000000; 
    color: white; 
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #ffcc00; 
}

.filter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-right: 10px;
    
}

.icon-style {
    height: 60px;
    width: 20px;
    background-color: rgb(255, 255, 255);
    display: inline-block;
}

.product-grid {
    width: 100%;
    display: grid;
    gap: 1rem;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
}

.grid {
    display: contents;
}

.Car-Profile-Pic {
    max-width: 100%;
    height: auto;
}

.productCard {
    position: relative;
    background-color: transparent;
    margin: 1rem;
    max-width: 40rem;
    max-height: 50rem;
    transition: 0.2s;
    text-align: left;
}

.productCard:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem;
}

.card-title {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 5px;
}
.card-price {
    font-size: 2rem;
}
.card-description {
    margin: 1rem;
}
.card-links {
    display: flex;
    flex-direction: row;
}

.monroney-labels > a {
          background: url(//labels-prod.s3.amazonaws.com/big-sticker.png);
          background-size: contain;
          display: block;
          height: 150px;
          margin: 0 auto;
          padding: 0;
          text-indent: -99999px;
          width: 150px;
      }
      .monroney-labels img {
          display: none !important;
      }
@media screen and (max-width: 960px) {
    .inventory-container {
        padding: 0 2rem;
    }
    .inventory-title h1{
        font-size: 3.5rem;
    }
    .product-grid {
        grid-template-columns: 1fr;
        padding: 1rem;
    }
    .card-body {
        flex-direction: column; 
        align-items: center; 
        text-align: center; 
        padding: 1rem;
        width: 100%;
    }
    .card-links {
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .icon-container {
        margin-right: 10px;
    }
    .card-title {
        font-size: 2rem;
    }
    .card-price {
        font-size: 1.5rem
    }
    .sort-dropdown-container {
        width: 100%;
        text-align: center;
    }

    .sort-dropdown {
        width: 100%;
    }
}



@media screen and (max-width: 600px) {

    .product-grid {
        grid-template-columns: 1fr;
    }
    .card-body {
        display: flex;
        flex-direction: column; /* Stack all elements vertically */
        align-items: start; /* Center align content */
        text-align: start;
        width: 100%; /* Prevent overflow */
        padding: 1rem; /* Add some spacing */
    }
    .card-title {
        font-size: 2rem;
    }
    .card-price{
        font-size: 1.5rem;
    }
    .icon-container {
        margin-right: 10px;
    }

    .sort-dropdown-container {
        width: 100%;
        text-align: center;
    }

    .sort-dropdown {
        width: 100%;
    }

    .filter-section .row {
        flex-direction: column; 
    }

    .filter-section .col-lg {
        width: 100%; 
        margin: 5px 0; 
    }
}