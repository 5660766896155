header {
  width: 100%;
  font-family: 'Stolzl', sans-serif;
  z-index: 1000;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 2px -2px rgb(255, 255, 255);
}
.sticky.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgb(255, 255, 255); 
  box-shadow: 0 4px 2px -2px gray; 
}
.navbar-container {
  display: flex;
  box-shadow: 0 4px 2px -2px gray;
  justify-content: space-between;
  align-items: center;
  height: 100px;  
  width: 100%;
  padding: 0 250px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  text-decoration: none;
}

#logo-icon {
  width: 80px !important;  
  height: auto !important;
}

#logo-title {
  width: 150px;
  height: auto;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 1rem;
}
.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 200px; 
  z-index: 1000;
  transform: translateY(0);
}

.dropdown-item {
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown-link {
  display: block;
  width: 100%;
  padding: 10px 0rem;
  color: #000; 
  text-decoration: none;
}

.dropdown-link:hover {
  background-color: #ecdf00; 
}
.nav-item {
  height: 50px;
  position: relative;
}

.nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding:  0.7rem;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 500;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.4s ease-out;
}

.fa-bars {
  color: #000;
}

.nav-links i {
  margin-left: 5px; /* Space for dropdown caret */
}
.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  /* Navbar container styles for mobile */
  .navbar-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    position: relative;
    margin-bottom: 0;
    width: 100%;
  }
  .dropdown-menu {
    position: relative;
    background-color: #000;  /* Same as the navbar for consistency */
    width: 100%;  /* Full width on mobile */
    padding: 10px 0;  /* Add padding for spacing */
    display: block;
    box-shadow: none;
    z-index: 999;
  }
  .dropdown-link{
    color: white;
  }
  .dropdown-item {
    padding: 15px;
    color: black;  /* White text for better visibility */
    text-align: center;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1; /* Highlight on hover */
    color: #000;
  }
  .logo-container {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .navbar-logo img#logo-icon {
    width: 80px;
    height: auto;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 30px;
    right: 50px;
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 1500;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #000;
    color: #fff;
    position: fixed;
    top: 110px;
    right: -300px;
    width: 250px;
    padding: 10px 0 0 10px;
    height:29vh;
    margin-top: 10px;
    
  }

  .nav-menu.active {
    right: -10px;
    
    transition: all 0.3s ease-in-out;
  }

  .nav-links {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background-color: #000; /* Ensure background matches the navbar */
    text-decoration: none;
  }




  /* Ensure proper stacking and visibility */
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: relative;
  }

}



#logo-icon {
  max-height: 150px !important;
  max-width: 180px !important;
  display: inline-block;
  object-fit: contain;
}

@media screen and (min-width: 961px) {
  .close-menu {
    display: none;
  }
}
