.showroom-container {
  font-family: 'Stolzl', sans-serif;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 0 130px;
}



/* From Uiverse.io by doniaskima */ 
.btn-17,
.btn-17 *,
.btn-17 :after,
.btn-17 :before,
.btn-17:after,
.btn-17:before {
  border: 0 solid;
  box-sizing: border-box;
}

.btn-17 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
}

.btn-17:disabled {
  cursor: default;
}

.btn-17:-moz-focusring {
  outline: auto;
}

.btn-17 svg {
  display: block;
  vertical-align: middle;
}

.btn-17 [hidden] {
  display: none;
}

.btn-17 {
  border-radius: 99rem;
  border-width: 2px;
  padding: 0.8rem 3rem;
  z-index: 0;
}

.btn-17,
.btn-17 .text-container {
  overflow: hidden;
  position: relative;
}

.btn-17 .text-container {
  display: block;
  mix-blend-mode: difference;
}

.btn-17 .text {
  display: block;
  position: relative;
}

.btn-17:hover .text {
  -webkit-animation: move-up-alternate 0.3s forwards;
  animation: move-up-alternate 0.3s forwards;
}

@-webkit-keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(80%);
  }

  51% {
    transform: translateY(-80%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(80%);
  }

  51% {
    transform: translateY(-80%);
  }

  to {
    transform: translateY(0);
  }
}

.btn-17:after,
.btn-17:before {
  --skew: 0.2;
  background: #fff;
  content: "";
  display: block;
  height: 102%;
  left: calc(-50% - 50% * var(--skew));
  pointer-events: none;
  position: absolute;
  top: -104%;
  transform: skew(calc(150deg * var(--skew))) translateY(var(--progress, 0));
  transition: transform 0.2s ease;
  width: 100%;
}

.btn-17:after {
  --progress: 0%;
  left: calc(50% + 50% * var(--skew));
  top: 102%;
  z-index: -1;
}

.btn-17:hover:before {
  --progress: 100%;
}

.btn-17:hover:after {
  --progress: -102%;
}



.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure buttons are spaced correctly */
  width: 100%; /* Full width for the wrapper */
  padding: 0 40px; /* Add padding for the buttons */
  box-sizing: border-box;
}

.scroll-button {
  background-color: rgba(0, 0, 0, 0.7); 
  border: none;
  color: white; 
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 100%; /* Circular buttons */
  position: relative;
}

.scroll-button.left {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-button.right {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.9); 
}

.showroom-content {
  display: flex;
  overflow-x: auto; 
  gap: 1rem;
  padding: 20px; 
  scroll-behavior: smooth;
  margin-left: 20px; 
}

.showroom-content::-webkit-scrollbar {
  display: none;
}

.showroom-content {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

.showroomCard {
  flex: 0 0 auto; 
  max-width: 30rem;
  margin-right: 1rem; 
  position: relative;
  background-color: transparent;
  margin: 1rem;
  transition: 0.2s;
  text-align: left;
}

.showroomCard:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.showroom-Profile-Pic {
  max-width: 100%;
  height: auto;
}

.showroom-card-body {
  display: flex;
  align-items: flex-start;
  margin: 2rem;
}

.showroom-card-title {
  font-size: 2rem;
}

.showroom-card-price {
  font-size: 1.5rem;
}

.showroom-container h1 {
  font-size: 2.5rem;
  font-weight: 900;
}

@media screen and (max-width: 768px) {
  .showroom-container {
    padding: 0 10px;
    margin-top: 1rem;
  }

  .showroom-content {
    gap: 1rem; /* Maintain gap between cards */
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
  }

  .showroomCard {
    min-width: 100%; /* Ensure only one card is displayed at a time */
    max-width: 100%;
    margin-right: 0.5rem;
    box-sizing: border-box; /* Ensure padding and borders are included in width */
  }

  .showroom-Profile-Pic {
    width: 100%; /* Ensure image takes full width of the card */
    height: auto; /* Maintain the aspect ratio */
    object-fit: cover; /* Prevent image distortion */
  }

  .showroom-card-body {
    margin: 1rem;
  }

  .showroom-card-title {
    font-size: 1.5rem;
  }

  .showroom-card-price {
    font-size: 1.2rem;
  }

  .scroll-button {
    font-size: 0.8rem;
    padding: 0.8rem;
  }
}
