html, body {
    width: 100%;
    font-family: 'Stolzl', sans-serif;
}
.contact-title-container{
    width: 100%;
    background-color: #ecdf00;
    display: flex;
    font-family: Arial, sans-serif;
    justify-content: start;
    padding: 20px 260px;
}
.contact-title{
    color: black;
    font-size: 30px;          
    font-weight: bold;
    margin-left: 1rem;
}
.contact-us-container {
    width: 100%;
    display: flex;
    height: 170vh;
    flex-direction: column;
}
.contact-info-section {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}
.contact-info-left {
    width: 45%;
    padding-right: 20px; 
    background-color:#fabb04;
}
.contact-us-title {
    font-weight: bold;
    color: black;
    font-size: 7vw; 
    margin-bottom: 10px;
    word-wrap: break-word; 
    overflow: hidden;
}
.contact-info-right {
    width: 55%;
}
.contact-info-left p{
    font-size: 1.7rem; 
    font-weight: bold;
    color: white;
    margin-left: 10px;
}
.contact-info-left .social {
    color: black !important;
}
.feedback-section {
    display: flex;
    flex-direction: row;  
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 600px;  
}
.map-container, 
.feedback-container {
    flex: 1;  
    padding: 20px 20px;
}

.map-container {
    height: 100%;  
    position: relative;
}
#mclaren {
    display: block;
    width: 100%; 
    height: auto; 
}
.feedback-form {
    margin: 0 auto;
    background: #fff;
  }
  
  .form-row {
    display: flex;
    gap: 20px; 
    margin-bottom: 15px;
}

.form-group {
    width: 100%;
}
  
  .feedback-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .feedback-form input,
  .feedback-form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .feedback-form textarea {
    height: 100px;
  }
  
  .feedback-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .feedback-form button:hover {
    background-color: #218838;
  }
  
  .feedback-header h2{
    display: flex;
    text-align: center;
    gap: 10px;
    font-weight: bold;
    font-size: 3vw;
  }
  
  .consent-box {
    text-align: left;
    margin-top: 15px;
}

.consent-box label {
    display: flex;
    align-items: flex-start; 
    font-size: 14px;
}

.consent-box input[type="checkbox"] {
    margin-right: 10px; 
    margin-top: 5px;
    vertical-align: top; 
}
@media (max-width: 960px){
    .contact-title-container{
        padding: 0 0;
    }
    .contact-us-container{
        height: 100%;   
    }
    .contact-us-container .contact-info-section {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .contact-us-container .contact-info-left, 
    .contact-us-container .contact-info-right {
        width: 100% !important;
    }
    .feedback-section {
        flex-direction: column;
        margin-bottom: 180px;
        height: auto;  /* Stack vertically on mobile */
    }
    
    .map-container {
        width: 100%;
        height: 350px; /* Set a height that fits well on mobile */
        padding: 10px;
    }

    .feedback-container {
        width: 100%;
        padding: 0 10px;
    }
}
@media (max-width: 768px){
    .contact-title-container{
        padding: 0 0;
    }
    .contact-us-container{
        height: 100%;   
    }
    .contact-us-container .contact-info-section {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .contact-us-container .contact-info-left, 
    .contact-us-container .contact-info-right {
        width: 100% !important;
    }
    .feedback-section {
        flex-direction: column;
        margin-bottom: 180px;
        height: auto;  /* Stack vertically on mobile */
    }
    
    .map-container {
        width: 100%;
        height: 350px; /* Set a height that fits well on mobile */
        padding: 10px;
    }

    .feedback-container {
        width: 100%;
        padding: 0 10px;
    }
}

@media (max-width: 430px) {
    .feedback-container {
        width: 100%;
        padding: 0 5px;  /* Adjust padding for smaller devices */
    }
    .contact-title-container {
        padding: 10px; /* Adjust padding for smaller screens */
    }
    .contact-us-container {
        padding: 10px;
    }
    .contact-info-left p {
        font-size: 1.2rem; /* Smaller font size for mobile */
    }


}
