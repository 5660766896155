html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

}

.service-container {
    font-family: 'Stolzl', sans-serif;
    padding: 20px;
    margin: 50px auto;
    max-width: 1500px;
    display: flex;
    gap: 20px;
}
.about-title-container{
    width: 100%;
    background-color: #ecdf00;
    display: flex;
    font-family: Arial, sans-serif;
    justify-content: start;
    padding: 20px 260px;
}
.about-title{
    color: black;
    font-size: 30px;          
    font-weight: bold;
    margin-left: 1rem;
}
.column {
    flex: 1;
}

.about-section, .location-section, .image-section, .social-media-section {
    margin-bottom: 40px;
    text-align: center;
}

.about-section h2 {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 10px;
}

.location-section{
    border: 2px solid #000;
    border-radius: 15px;
    padding: 10px;
}

.location-section h3 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 10px;
}

.location-section address {
    margin-bottom: 10px;
}

.about-section p{
    font-size: 18px;
    text-align: start;
}

.location-section p{
    font-size: 18px;
}

button {
    /* Variables */
    --button_radius: 0.75em;
    --button_color: #e8e8e8;
    --button_outline_color: #000000;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: var(--button_radius);
    background: var(--button_outline_color);
  }
  
  .button_top {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
  }
  
  button:hover .button_top {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
  }
  
  button:active .button_top {
    /* Push the button downwards when pressed */
    transform: translateY(0);
  }

.image-section {
    text-align: center;
}

.car-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border: 2px solid #333;
}

.social-media-section h3 {
    font-size: 50px;
    text-align: center;
}

.social-media-buttons {
    display: flex;
    justify-content: center;
    gap: 65px;
  }
  
.social-media-button {
display: flex;
margin-top: 50px;
align-items: center;
justify-content: center;
width: 250px;
height: 100px;
border-radius: 20px;;
color: white;
text-decoration: none;
transition: background-color 0.3s;
font-size: 50px;
}

.twitter {
background-color: #000;
}
.instagram {
background-color: #e4405f;
}

@media (max-width: 768px) {
    .about-title-container{
        padding: 0 0;
    }
    .service-container, .social-media-section {
        flex-direction: column;
        align-items: center;
        
    }
    .image-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .car-image {
        width: 90%;
        max-width: 400px;
    }
    .social-media-buttons {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    .social-media-button {
        width: 40%;
        max-width: 150px;
        height: 150px;
        font-size: 30px;
    }

    .social-media-section h3 {
        font-size: 40px;
    }
}


  

  @media (max-width: 480px) {
    .social-media-button {
        width: 100%;
        height: 60px;
        font-size: 24px;
    }

    .social-media-section h3 {
        font-size: 30px;
    }
}
  
