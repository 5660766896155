.intro-container {
  width: 100%;
  height: 87vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Stolzl', sans-serif;
  position: relative;
  margin: 0; 
  padding: 0;
}

#intro {
  position: absolute;
  width: 100%;
  height: 87vh;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover; 
}
.home_filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 25px; 
  left: 50%;
  transform: translateX(-50%); 
  background-color: rgba(0, 0, 0, .3); 
  z-index: 1;
  width: 80%; 
  max-width: 1200px; 
}
.home_search-button {
  background-color: #000; 
  color: white; 
  cursor: pointer;
  transition: background-color 0.3s;
}
.home_search-button:hover {
  background-color: #ffcc00; 
}
.home_filter-section .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; 
  margin-left: -15px;
  margin-right: -15px;
  padding: 0px;
  box-shadow: none;
}
.home_filter-section .row-items {
  flex: 1;
  margin: 2px;
  display: flex;
  justify-content: center; 
}
.home_year-dropdown,
.home_brand-dropdown,
.home_model-dropdown,
.home_search-button {
  width: 100%;
  height: auto;
  padding: 5px 10px; 
  max-width: 250px;
  border: 1px solid #ffffff;
  border-radius: 0; 
  text-align: center;
  background-color: #ffffff;
  color: black;
  font-family: 'Stolzl', sans-serif;
  font-weight: bold;
}
.home_year-dropdown,
.home_brand-dropdown,
.home_model-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none; 
  appearance: none; 
  text-align: start;
  background-color: rgba(255, 255, 255, 0); 
  color: rgb(255, 255, 255); 
}

.home_year-dropdown:hover,
.home_brand-dropdown:hover,
.home_model-dropdown:hover,
.home_year-dropdown:focus,
.home_brand-dropdown:focus,
.home_model-dropdown:focus {
  background-color: #000; 
  color: white; 
}


@media (max-width: 960px) {
  .intro-container {
    max-height: 36vh;
  }
  #intro {
      height: auto;
  }
  .home_filter-section{
    height: 18vh;
    width: 100%;
    bottom: 0px;
    background-color: black;
  }
  .home_filter-section .row {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-gap: 10px;
    justify-items: stretch;
    align-items: center;
  }

  .home_filter-section .row-items {
    width: 100%;
  }

  .home_year-dropdown,
  .home_brand-dropdown,
  .home_model-dropdown {
    width: 100%; 
  }

  .home_search-button {
    grid-column: span 2; 
    width: 100%;
  }
  
}



@media (max-width: 768px) {
  .intro-container {
    height: auto;
  }

  .home_filter-section .row {
    grid-template-columns: 1fr 1fr; 
    grid-gap: 10px; 
    justify-items: stretch; 
  }

  .home_filter-section .row-items {
    display: block;
    width: 100%;
  }

  .home_year-dropdown,
  .home_brand-dropdown,
  .home_model-dropdown {
    display: block;
    width: 100%;
  }

  .home_search-button {
    grid-column: span 2;
    width: 100%;
  }
  
}
