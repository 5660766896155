.Footer {
  font-family: 'Stolzl', sans-serif;
  background-color: #222f3e;
  color: #ffffff;
  width: 100%;
  padding: 0px 10rem;
}

.footer-logo-container {
  text-align: start;
  margin-left: 5rem;
  position: relative;
}
.footer-logo-container img{
  height: auto;
  width: 200px;
}
#logo-footer {
  width:  200px !important;  
  max-width: 100% !important; 
}

.footer-content-container {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;
  padding: 10px 2rem;
  flex-wrap: wrap; 
}

.footer-links,
.footer-schedule,
.footer-location,
.footer-description {
  flex: 1; 
  margin: 0 1rem; 
  min-width: 250px; 
  font-size: 0.8rem;
  text-align: left;
}
.footer-schedule p{
  margin-top: 15px;
}
.footer-schedule{
  padding-bottom: 25px;
}

.footer-links ul {
  list-style: none;
  margin-left: 3rem;
  padding: 0;
}



.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-schedule, .footer-location, .footer-description {
  margin-bottom: 2rem;
}

.footer-location h5 {
  margin-bottom: 1rem;
}

.footer-location p {
  margin: 1rem 0;
  font-size: 1rem;
}
.footer-location {
  margin-bottom: 93px;
}
.footer-description h3 {
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}

.footer-description p {
  font-weight: 600;
}

.social-icons {
  margin: 0 10px;
}

.Last-footer {
  background: #222f3e;
  text-align: center;
  border-top: 1px solid #ffffff;
  color: white;
}

.Last-footer p {
  margin: 0;
  font-size: 1rem;
  font-weight: 900;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .Footer {
    padding: 0 20px; /* Reduce padding on mobile */
    text-align: start; /* Center content for better alignment */
  }

  .footer-content-container {
    flex-direction: column; /* Stack items vertically */
    text-align: center;
    padding: 0; /* Remove additional padding */
  }

  .footer-logo-container {
    margin-left: 30px; 
    text-align: center;

  }

  .footer-logo-container img {
    width: 150px;
    margin: 20px;
  }

  .footer-links,
  .footer-schedule,
  .footer-location,
  .footer-description {
    margin-bottom: 20px;
    min-width: 100%; /* Full width for each section */
    text-align: center;
  }

  .footer-links ul {
    padding: 0; /* Ensure no excessive padding */
    margin: 0;
  }

  .footer-links ul li {
    margin-bottom: 10px; /* Space between links */
  }

  .footer-links a {
    font-size: 1rem; /* Adjust font size */
  }

  .footer-schedule h4,
  .footer-location h2,
  .footer-description h3 {
    font-size: 1.4rem; /* Adjust heading size */
    margin-bottom: 10px;
  }

  .footer-schedule p,
  .footer-location p,
  .footer-description p {
    font-size: 1rem; /* Adjust paragraph font size */
  }

  .footer-description p {
    margin-bottom: 10px;
  }

  .social-icons {
    margin: 0 10px; /* Ensure social icons are spaced out */
    width: 40px; /* Adjust size for mobile */
    height: 40px;
  }

  .Last-footer {
    padding: 10px 0; /* Reduce padding for last footer section */
  }
}
