html, body {
    height: 100%;
    width: 100%;
    font-family: 'Stolzl', sans-serif;
}
.intro-container {
    width: 100%;
    height: 87vh;
}
.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.adBox-container {
    background: black;
    width: 100%;
    padding: 0px 280px;
    text-align: center;
    display: flex;
    position: relative;
    flex-direction: column;  
    align-items: center;
    z-index: 0;
}
.adBox-title {
    color: white;            
    font-size: 2.5rem;       
    margin-bottom: 20px;     
    font-weight: bold;
}
.adBox-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    gap: 20px;                              
    width: 100%;
    justify-items: center;                  
}
.adBox-item {
    color: white;
    padding: 20px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    min-height: 150px;                     
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.adBox-item:first-child {
    border-left: none; 
}
.adBox-item h{
    font-size: 1.5rem;
    margin-bottom: 10px;
}
.adBox-item .anticon{
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.description-container{
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: stretch;
    background-color: #d2d8db;
    width: 100%;
    padding: 20px 280px;
}
  .description-left {
    width: 65%; 
  }
  
  .description-right {
    width: 35%;
    margin-left: 15px;
    background-color:#d2d8db;
  }
  
  .description-right h2 {
    color: #fabb04;
    font-weight: bold;
    font-size: 5vw; 
    margin-bottom: 10px;
    word-wrap: break-word; 
    overflow: hidden;
  }
  
  .description-right p {
    font-size: 1.4rem; 
    font-weight: bold;
    color: black;
  }
  
  #frontShop {
    display: block;
    width: 100%; 
    height: 510px; 
  }


.learnMore-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}

.learnMore-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 13rem;
}
.learnMore-image-container img {
  max-width: 49%; 
  height: auto;
  margin-top: 20px;
}
.learnMore-container h1 {
  color: black; 
}
.learnMore-container h2 {
  color: black;
  margin-top: 15px;
}

.learnMore-container p {
  margin-bottom: 20px; 
  font-size: 1.3rem; 
}

.learnMore-btn {
  background-color: black; 
  color: white; 
  border: none; 
  padding: 10px 30px;
  border-radius: 50px; 
  font-size: 1rem; 
  font-weight: bold; 
  cursor: pointer; 
  transition: background-color 0.3s ease;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  width: fit-content; 
  margin: 1rem;
}

.learnMore-btn:hover {
  background-color: #333; 
}

@media (max-width: 768px) {
    .adBox-container {
        width: 100%;
        padding: 0px 5px;
    }
    .adBox-item {
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, 0.3); 
    }
    .description-container {
        flex-direction: column;
        padding: 10px 20px; 
      }
      #frontShop{
        height: auto;
      }
    
      .description-left, .description-right {
        width: 100%; 
      }
    
      .description-right {
        padding-left: 0; 
        margin-top: 20px; 
      }
      .description-right h2 {
        font-size: 18vw; 
      }
      .learnMore-container {
        padding: 0 20px; /* Add padding to prevent content from touching screen edges */
        text-align: center; /* Center align text */
      }
    
      .learnMore-image-container {
        flex-direction: column; /* Stack images vertically on mobile */
        justify-content: center;
        align-items: center;
        padding: 0; /* Remove excessive padding */
      }
    
      .learnMore-image-container img {
        max-width: 90%; /* Make images responsive */
        height: auto;
        margin: 10px 0; /* Add space between images */
      }
    
      .learnMore-container h1 {
        font-size: 1.8rem; /* Adjust the font size for smaller screens */
        margin-top: 10px;
      }
    
      .learnMore-container p {
        font-size: 1.1rem; /* Adjust paragraph font size */
        margin-bottom: 10px;
      }
    
      .learnMore-btn {
        padding: 10px 20px; /* Adjust button padding */
        font-size: 1rem;
        margin-top: 10px;
      }
}

@media (max-width: 480px) {
    .adBox-grid {
        grid-template-columns: 1fr;  
    }
    .adBox-item {
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, 0.3); 
    }
}
