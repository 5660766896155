.car-details-page {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  padding: 0 17rem;
  font-family: Arial, sans-serif;
}
.back-to-inventory {
  font-size: 20px;
  color: black;
  cursor: pointer;
  text-align: left;
  position: flex;

}


.car-details-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  padding: 0 20px;
  
}
.left-section {
  flex: 0 0 60%;
}

.right-section {
    flex: 0 0 40%; 
    display: flex;
    flex-direction: column; 
    align-items: center;
}


.image-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image-carousel img {
  width: 100%;
  object-fit: cover;
}

.car-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  border-radius: 25px;
  flex: 1;
  width: 100%; 
  margin-top: 15px;
}

.car-detail {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.car-detail-title {
  grid-column: span 2; 
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 10px 0px;
  background-color: #ffffff;
}

.vehicle-detail-title {
  font-weight: bold;
  font-size: 40px;
}

.carfax-link {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.car-detail-label {
  font-weight: bold;
}
.car-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.car-description-title {
  font-weight: bold;
  font-size: 40px;
  padding: 10px 0px;
}
.car-description-content {
  font-size: 20px;
  background: #eeeeee;
  border-radius: 25px;
  padding: 15px 15px;
}
.car-detail-value {
  color: #000000;
  font-weight: bold;
  padding: 0 5px;
}
.car-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 1rem;
}
.car-equipment {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.car-equipment-title {
  font-weight: bold;
  font-size: 40px;
  padding: 10px 0px;
}
.equipment-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 10px; 
  list-style-type: none;
  padding-left: 0;
}

.equipment-item {
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  padding: 5px;
}

.equipment-item li {
  list-style-type: disc;
  margin-left: 20px;
}

.loan-calculator {
  background-color: #222f3f;
  border: 1px solid #ccc;
  margin-bottom: 50px;
  max-width: 500px;
  width: 100%;
  height: auto;
  padding: 10px;
}
.loan-calculator-label,
.loan-calculator-button,
.loan-calculator-value {
  color: white;
  padding: 5px;
}
.loan-calculator-title{
  text-align: center;
  padding: 25px;
  font-weight: bold;
  color: white;
  font-weight: 700;
  margin-top: 20px;
}
.loan-calculator-label {
  font-weight: bold;
  margin-right: 5px;
  background-color: black;
  font-size: 1.5rem;
}
.loan-calculator-row {
  padding: 5px;
  background-color: #222f3f;
  width: 100%;
}
.loan-calculator-value, .loan-calculator-input {
  background-color: #444; 
  color: white;
  padding: 10px;
  text-align: right;
  border: none;
  font-size: 1.3rem;
}

.loan-calculator-input {
  font-size: 1rem;
  color: white;
}
.loan-calculator-value {
  font-size: 1.3rem;
}
.loan-calculator-button span{
  color: white;
}
.loan-calculator-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; 
  margin: 20px auto; 
  text-align: center;
}
.right-section h5{
  font-weight: 700;
  font-size: 3rem;
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
}
.loan-calculator-button:hover {
  background-color: #fabb04;
}

.loan-calculator-result {
  background-color: #f0f8ff; 
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}

.loan-calculator-result-text {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
}
/* From Uiverse.io by adamgiebl */ 
.cssbuttons-io-button {
  background: black;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
  max-width: 200px;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  transform: rotate(180deg);  
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: rotate(180deg); 
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}
.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure buttons are spaced correctly */
  width: 100%; /* Full width for the wrapper */
  padding: 0 40px; /* Add padding for the buttons */
  box-sizing: border-box;
}
.scroll-button {
  background-color: rgba(0, 0, 0, 0.7); 
  border: none;
  color: white; 
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 100%; /* Circular buttons */
  position: relative;
}

.scroll-button.left {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-button.right {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.9); 
}
.similar-cars-container{
  width: 100vw;
  text-align: center;
  margin-left: calc(-17rem); 
  margin-right: calc(-17rem);
  background-color: #536872;
  padding: 10px 0;
  margin-top: 20px;
  margin-bottom: 0;
}
.similar-cars-container h2{
  margin-top: 10px;
  color: white;
  font-weight: 700;
}
.similar-cars-content{
  display: flex;
  flex-direction: row; 
  overflow-x: auto; 
  gap: 20px; 
  padding: 20px;
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
.similar-Card{
  flex: 0 0 auto; 
  max-width: 22rem;
  margin-right: 1rem; 
  position: relative;
  background-color: white;
  margin: 1rem;
  transition: 0.2s;
  text-align: left;
}
.similar-Card:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}

.similar-Profile-Pic {
  max-width: 100%;
  height: auto;
}

.similar-card-body {
  display: flex;
  align-items: flex-start;
  margin: 2rem;
}

.similar-card-title {
  font-size: 2rem;
}

.similar-card-price {
  font-size: 1.5rem;
}
.monroney-labels > a {
  background: url(//labels-prod.s3.amazonaws.com/big-sticker.png);
  background-size: contain;
  display: block;
  height: 150px;
  margin: 0 auto;
  padding: 0;
  text-indent: -99999px;
  width: 150px;
}
.monroney-labels img {
  display: none !important;
}
@media (max-width: 960px) {
  .car-details-page {
    padding: 0 2rem;
  }
  .car-detail-title span{
    padding: 0 10px;
  }
  .car-title {
    position: relative;
    font-size: 45px;
    font-weight: bold;
    background: white;
    padding: 0px 0px;  
    margin: 0;  
    text-align: start;  
}
  .car-details-grid {
    padding: 0 20px;
  }
  .car-details-grid {
    padding: 0 50px;
  }
  .car-details-content {
      flex-direction: column;
      align-items: center;
  }

  .carousel-container {
      max-width: 100%;
      margin-right: 0;
      height: auto; 
  }
  .right-section {
    width: 40vh;
    margin-top: 20px;
  }
  .car-details-grid {
      grid-template-columns: 1fr;
      margin-top: 20px;
      width: 100%;
  }

  .car-detail {
      flex-direction: column;
      align-items: flex-start;
  }

  .car-detail-label {
      margin-bottom: 5px;
  }
}


@media (max-width: 768px) {
  .car-title {
    font-size: 8vw; 
  }
  .vehicle-detail-title {
    font-size: 5vw;
  }
  .car-details-grid {
    grid-template-columns: 1fr; 
    gap: 10px; 
  }

  .car-detail {
    flex-direction: column; 
    align-items: flex-start;
  }

  .loan-calculator {
    width: 100%; 
    padding: 10px; 
  }

  .loan-calculator-title {
    font-size: 1.2rem; 
  }

  .loan-calculator-row {
    flex-direction: column; 
    padding: 5px 0; 
  }

  .loan-calculator-label {
    font-size: 1rem; 
  }

  .loan-calculator-input {
    width: 100%;
    margin-top: 5px; 
  }

  .loan-calculator-button {
    width: 100%; 
    padding: 10px;
    font-size: 1.1rem;
  }

  .loan-calculator-result {
    padding: 10px;
    font-size: 1.2rem; 
  }
  .similar-cars-container {
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    background-color: #536872;
    padding: 10px 0;
    margin-top: 20px;
  }

  .similar-cars-content {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;  /* Allow horizontal scrolling on mobile */
    gap: 10px;
    padding: 10px;
  }

  .similar-Card {
    flex: 0 0 auto;
    max-width: 80%;  /* Adjust card size for mobile screens */
    margin-right: 1rem;
    background-color: white;
    margin: 1rem;
    transition: 0.2s;
    text-align: left;
  }

  .similar-Profile-Pic {
    max-width: 100%;
    height: auto;
  }

  .similar-card-body {
    margin: 1rem;
  }

  .similar-card-title {
    font-size: 1.5rem;  /* Smaller font size for mobile */
  }

  .similar-card-price {
    font-size: 1.2rem;  /* Smaller price text */
  }
}
