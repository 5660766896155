.carousel-item img {
  height: 500px; 
  object-fit: cover;
}
.fullscreen-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}
.carousel-item-wrapper {
  padding: 0 5px; 
}
.carousel-container-full-width {
  width: 100vw; /* Full width of the viewport */
  margin-left: calc(-50vw + 50%); /* Center-align the full-width container */
}
