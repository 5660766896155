.contactBoxContainer{
    width: 100%; 
    font-family: Arial, sans-serif;
    margin: 0 auto; 

}
.contactBox-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background: #E9EAEC;
  }
  
  .contactBox-header h2 {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .contactBox-form div {
    margin-bottom: 15px;
  }
  
  .contactBox-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contactBox-form input,
  .contactBox-form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  
  .contactBox-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .contactBox-form button:hover {
    background-color: #218838;
  }
  
  .contactBox-header h2{
    display: flex;
    text-align: center;
    gap: 10px;
  }

