html, body {
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
.finder-title-container{
    width: 100%;
    background-color: #ecdf00;
    display: flex;
    font-family: Arial, sans-serif;
    justify-content: start;
    padding: 20px 260px;
}
.finder-title{
    color: black;
    font-size: 30px;          
    font-weight: bold;
    margin-left: 1rem;
}
.car-finder-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
}
.car-finder-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
    gap: 20px;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #fff;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.submit-btn {
    width: 100%;
    padding: 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #218838;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
    .finder-title-container{
        padding: 0 0;
    }
    .form-row {
        flex-direction: column;
    }

    .car-finder-container {
        max-width: 100%;
        padding: 15px;
    }
}